// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import "popperjs"
import "bootstrap";
import "../stylesheets/application.scss";
import 'bootstrap-icons/font/bootstrap-icons.css'
window.bootstrap = require('bootstrap');
import "chartkick/chart.js"
// import numeral from "numeral"
require('flatpickr')
require('flatpickr/dist/l10n/ja')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "./zipcode.js"
import "./bill.js"
import "./calendar.js"


//商品サブカテゴリの取得
window.product_category_change = function(){
  // ↓送信したいURLと渡したいデータを指定
  var value = document.getElementById("master_product_product_category_id").value;

  axios.get('/master/products/change_category', {params:{category_id: value}}, {headers: {'content-type': 'application/json'}})
    .then(function (res) {
      // console.log(res.data.subcategories)
      //今までのデータを削除
      var select = document.getElementById("master_product_product_subcategory_id");
      while(select.lastChild)
      {
        select.removeChild(select.lastChild);
      }
      var option = document.createElement("option");
      option.text = "選択してください";
      // optionタグのvalueを4に設定する
      option.value = "";
      // selectタグの子要素にoptionタグを追加する
      select.appendChild(option);

      res.data.subcategories.forEach(function(val,index,ar){
        //ここでselectを作成する！！
        // console.log(val.name);

        // optionタグを作成する
        var option = document.createElement("option");
        // optionタグのテキストを4に設定する
        option.text = val.name;
        // optionタグのvalueを4に設定する
        option.value = val.id;
        // selectタグの子要素にoptionタグを追加する
        select.appendChild(option);
      });
    })
    .catch(function (reason) {
      console.log(reason)
    })
}





window.buy_rate_change = function(){

  const base_price = document.getElementById("order_detail_base_price").value;
  let buy_rate = document.getElementById("order_detail_buy_rate").value;
  let buy_price_elem = document.getElementById("order_detail_price");

  buy_price_elem.value = Math.ceil((base_price * Number(buy_rate)));

}

window.buy_price_change = function(){

  const base_price = document.getElementById("order_detail_base_price").value;
  if (base_price > 0) {
    let buy_rate_elem = document.getElementById("order_detail_buy_rate");
    let buy_price = document.getElementById("order_detail_price").value;
    buy_rate_elem.value = Math.ceil(buy_price * 100 / base_price) / 100;
  }
}

window.other_product_name_change = function(){
  // ↓送信したいURLと渡したいデータを指定
  var name = document.getElementById("order_detail_product_name").value;
  axios.get('/master/other_products/price', {params:{name: name}}, {headers: {'content-type': 'application/json'}})
    .then(function (res) {
      if (res.data.price){
        var elem = document.getElementById("order_detail_price");
        elem.value =  res.data.price;

        elem = document.getElementById("order_detail_cost");
        elem.value =  res.data.cost;

      }
    })
    .catch(function (reason) {
      console.log(reason)
    })
}

window.date_show = function(){
  flatpickr.localize(flatpickr.l10ns.ja)
  const config = {
    allowInput: true,
    disableMobile: false
  }
  flatpickr('.calendar_form1', config);
}

